// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/henri/Desktop/git/vpinteriors/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-template-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-press-template-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/templates/pressTemplate.js" /* webpackChunkName: "component---src-templates-press-template-js" */),
  "component---src-pages-404-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-clients-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-projects-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-commercial-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/projects/commercial.js" /* webpackChunkName: "component---src-pages-projects-commercial-js" */),
  "component---src-pages-projects-hospitality-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/projects/hospitality.js" /* webpackChunkName: "component---src-pages-projects-hospitality-js" */),
  "component---src-pages-services-js": () => import("/Users/henri/Desktop/git/vpinteriors/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

exports.data = () => import("/Users/henri/Desktop/git/vpinteriors/.cache/data.json")

